@import "~antd/dist/antd.css";

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

#__next {
  min-height: 100%;
}

.editable-cell {
  position: relative;
}

.editable-cell::after {
  position: absolute;
  top: 50%;
  right: 5px;

  display: block;

  width: 15px;
  height: 15px;

  content: "";
  transform: translateY(-50%);

  opacity: 0;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 503 503.125' xmlns='http://www.w3.org/2000/svg' fill='%23ccc'%3E%3Cpath d='M16.05 503.125a15.93 15.93 0 01-11.304-4.691 15.959 15.959 0 01-4.203-15.168L30.578 362.71a16.026 16.026 0 014.203-7.445L372.895 17.152c22.867-22.87 60.093-22.87 82.964 0l30.164 30.164c22.872 22.872 22.872 60.098 0 82.965L147.914 468.395a15.896 15.896 0 01-7.445 4.203L19.914 502.633a14.784 14.784 0 01-3.863.492zm44.481-128.383l-22.527 90.41 90.41-22.527L463.41 107.648c10.39-10.39 10.39-27.328 0-37.718l-30.164-30.164c-10.41-10.41-27.351-10.391-37.719 0zm76.074 82.348h.215zm0 0'/%3E%3Cpath d='M422.688 187.008c-4.098 0-8.196-1.559-11.31-4.692L320.864 91.82c-6.254-6.254-6.254-16.386 0-22.636 6.25-6.25 16.383-6.25 22.633 0l90.52 90.52c6.25 6.25 6.25 16.382 0 22.632-3.16 3.113-7.254 4.672-11.329 4.672zM136.605 473.09c-4.097 0-8.19-1.559-11.304-4.695l-90.52-90.516c-6.25-6.254-6.25-16.387 0-22.637s16.383-6.25 22.637 0l90.516 90.52c6.25 6.25 6.25 16.383 0 22.633a15.956 15.956 0 01-11.329 4.695zm0 0'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 13px;
}

.editable-cell:hover::after {
  opacity: 1;
}

.mini-card .ant-card-head {
  min-height: 32px;
  padding: 0;

  color: rgb(0 0 0 / 45%);

  border: none;

  font-weight: 400;
}

.mini-card .ant-card-head-title {
  padding-top: 0;
  padding-bottom: 4px;

  font-size: 14px;
}

.mini-card .ant-card-extra {
  padding-top: 0;
  padding-bottom: 4px;
}

.mini-card .ant-card-body {
  padding: 0;
}

.partner-page-header .ant-page-header-heading-title {
  white-space: normal;
}
